.container {
  .navbar {
    background-color: var(--dark-red);
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    z-index: 999;

    .navbarLogo {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 25px;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      .navbarLogo__image {
        height: 50px;
        width: 50px;
        padding-left: 25px;
      }

      .navbarLogo__title {
        color: #fff;
        font-weight: bold;
        font-size: 20px;
        font-family: AirbnbCereal;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .mobileMenuIcon {
    // only shows up for mobile
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin-left: 0;
    margin-right: 0;

    .navbar {
      top: 0;
      z-index: 999;

      .navbarLogo {
        .navbarLogo__title {
          width: 100px;
          font-size: 14px;
        }

        .navbarLogo__image {
          height: 30px;
          width: 30px;
          padding-left: 25px;
        }
      }

      .mobileMenuIcon {
        display: flex;
        padding: 0px;
        margin-right: 30px;
        color: white;
        text-decoration: none;
      }
    }
  }
}
