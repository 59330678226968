.form {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 60%;

  .fields {
    display: flex;
    flex-direction: column;
    row-gap: 25px;

    .field {
      display: flex;
      flex-direction: column;
    }
  }
}
