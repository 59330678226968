.funding {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 75px;

  .steps {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-left: 75px;
    margin-bottom: 25px;
    margin-top: 50px;
  }

  .steps__title {
    color: var(--dark-red);
    font-family: AirbnbCereal-Bold;
    font-size: 28px;
  }

  .steps__stepInfo {
    font-family: AirbnbCereal;
    font-size: 18px;
    margin-top: 15px;
  }

  .eligibilty {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-bottom: 25px;
    padding-left: 75px;
  }

  .elibility__title {
    color: var(--dark-red);
    font-family: AirbnbCereal-Bold;
    font-size: 28px;
  }

  .eligibilty__info {
    font-family: AirbnbCereal;
    font-size: 18px;
    margin-top: 15px;
  }

  .funding__applyButton {
    font-family: AirbnbCereal-Bold;
    font-size: 14px;
    color: #fff;
    background-color: var(--dark-red);
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 5px;
  }

  .funding__applyButton:hover {
    background-color: var(--light-red);
    cursor: pointer;
  }
}
