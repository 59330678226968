.resources {
  width: 100%;

  .launchTalks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(228, 166, 166, 0.2);
    width: 100%;
    padding-bottom: 50px;

    .launchTalks__title {
      width: 90%;
      font-family: AirbnbCereal-Bold;
      color: var(--dark-red);
      margin-bottom: 45px;
      padding-top: 50px;
    }

    .launchTalks__cards {
      width: 95%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-wrap;
      justify-content: center;
      align-items: center;
      gap: 45px;
    }
  }
}

@media screen and (max-width: 768px) {
  .launchTalks__title {
    text-align: center;
  }
}
