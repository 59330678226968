.aboutPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .infoBlocks {
    display: flex;
    flex-direction: column;
  }

  .board {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(228, 166, 166, 0.2);
    width: 100%;
    padding-bottom: 50px;

    .board__title {
      width: 85%;
      font-size: 28pt;
      font-family: AirbnbCereal-Bold;
      color: var(--dark-red);
      padding-bottom: 45px;
      padding-top: 45px;
    }

    .board__members {
      width: 85%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-wrap;
      justify-content: center;
      align-items: center;
      gap: 45px;
    }
  }
}
