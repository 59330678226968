.tb_container {
  max-width: 350px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.tb_title {
  color: var(--light-red);
  font-family: AirbnbCereal-Bold;
  font-size: 24pt;
}

.tb_image {
  max-height: 175px;
}

.tb_text {
  text-align: center;
  margin: 0px;
  font-family: AirbnbCereal;
  font-size: 14pt;
}

.tb_action_button {
  color: #fff;
  background-color: var(--light-red);
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: AirbnbCereal;
  font-weight: bold;
  font-size: 20px;
  height: 50px;
  max-width: 350px;
  text-decoration: none;
}
