.home {
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/images/home/aasampitheater_cc_wide.jpeg);
    background-size: 100% auto;

    img {
      width: 70%;
      margin-top: 50px;
    }

    button {
      color: #fff;
      background-color: var(--light-red);
      padding: 10px;
      outline: none;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-family: AirbnbCereal;
      font-weight: bold;
      font-size: 22px;
      height: 60px;
      width: 200px;
      margin-bottom: 50px;
      margin-top: 50px;
    }

    p {
      color: white;
      font-family: AirbnbCereal;
      font-weight: bold;
      font-size: 36px;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-bottom: 80px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .topicsSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 75px 50px;
    gap: 50px;
  }

  .companiesSection {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 75px;
    text-align: center;
    margin-bottom: 125px;

    .companiesSection__title {
      color: var(--light-red);
      font-family: AirbnbCereal-Bold;
      font-size: 24pt;
    }

    .companiesSection__logos {
      width: 85%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 75px;

      .company__logo__width {
        align-self: center;
        width: 150px;
        height: auto;
      }

      .company__logo__height {
        align-self: center;
        width: auto;
        height: 65px;
      }

      .company__logo__last {
        align-self: center;
        width: auto;
        height: 35px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .group_LaunchDeck_image {
      width: 90%;
      margin-top: 25px;
      margin-bottom: 0px;
    }
    .group_LaunchDeck_join_button {
      max-width: 125px;
      max-height: 50px;
      margin-top: 25px;
      margin-bottom: 0px;
      font-size: 20px;
    }
    .group_LaunchDeck_title {
      font-size: 20px;
      margin-top: 25px;
      margin-bottom: 50px;
    }

    .company_section_container {
      margin-top: 25px;
      margin-bottom: 75px;
      gap: 50px;
    }

    .company_logo_container {
      width: 85%;
      gap: 50px;
    }

    .company_title {
      color: var(--light-red);
      font-family: AirbnbCereal-Bold;
      font-size: 18pt;
    }

    .company_logo_width {
      align-self: center;
      width: 0.8 * 150px;
      height: auto;
    }

    .company_logo_height {
      align-self: center;
      width: auto;
      height: 0.8 * 65px;
    }

    .company_logo_toobig {
      align-self: center;
      width: auto;
      height: 0.8 * 35px;
    }
  }
  // overriding the mobile width rule due to the background image dimension
  @media screen and (max-width: 600px) {
    .group_LaunchDeck {
      background-size: auto 100%;
    }

    .company_title {
      width: 85%;
    }
  }
}
