.mobileMenu {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobileMenu {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    background-color: var(--dark-red);
    height: auto;
    width: 100%;
    position: absolute;
    top: 80px;
    left: 0px;
    right: 0px;
    transition: all 0.5s ease;

    .items {
      display: flex;
      flex-direction: column;
      align-items: center;

      .menuItem {
        display: flex;
        height: 40px;
        padding: 15px;
      }

      .menuItem__label {
        color: #fff;
        font-size: 18px;
        font-family: AirbnbCereal;
        text-decoration: none;

        &:hover {
          color: var(--pink);
        }
      }
    }

    .socials {
      display: flex;
      flex-direction: row;
      width: 140px;
      height: 60px;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0px;

      .social {
        .social__logo {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
