.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  height: 375px;
  width: 225px;
  padding: 25px 25px 25px 25px;

  .content {
    justify-content: bottom;
    align-items: bottom;
    margin-top: 0px;
  }

  .content__heading {
    font-family: AirbnbCereal-Bold;
    color: var(--dark-red);
    font-size: 18pt;
  }

  .content__subHeading {
    font-family: AirbnbCereal;
    color: var(--light-red);
    font-size: 12pt;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .content__date {
    font-family: AirbnbCereal;
    font-size: 10pt;
  }

  .visual {
    display: flex;
    flex-direction: column;
    justify-content: bottom;
    align-items: center;
    margin-bottom: 0px;
  }

  .visual__image {
    height: 225px;
    width: 225px;
  }

  .visual__button {
    font-family: AirbnbCereal-Bold;
    color: #fff;
    background-color: var(--light-red);
    font-size: 10pt;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    width: 150px;
    padding: 10px;
  }

  .visual__button:hover {
    background-color: var(--pink);
  }
}
