.menu {
  display: flex;
  gap: 50px;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 40px;
  margin-left: 50px;

  .menuItem {
    .menuItem__label {
      color: #fff;
      font-size: 18px;
      font-family: AirbnbCereal;
      text-decoration: none;

      &:hover {
        color: var(--pink);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .menu {
    display: none;
  }
}
