.newsletter {
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
  row-gap: 25px;
  align-items: center;

  .newsletter__title {
    font-family: AirbnbCereal;
    color: var(--light-red);
    text-align: center;
    font-size: 40px;
    width: 60%;
  }

  .newsletter__description {
    font-family: AirbnbCereal;
    font-size: 18px;
    line-height: 200%;
    width: 60%;
  }
}
