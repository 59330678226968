.block {
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 85% !important;
    margin-bottom: 75px !important;
    margin-top: 75px !important;
    column-gap: 100px;

    .info {
      display: flex;
      flex-direction: column !important;

      .info__title {
        width: 80%;
        color: var(--dark-red);
        font-family: AirbnbCereal-Bold;
        font-size: 28pt;
        justify-content: left;
        align-items: left;
        margin-bottom: 0px;
        padding-bottom: 20px;
      }

      .info__description {
        margin: 0px;
        font-family: AirbnbCereal;
        font-size: 16pt;
        padding-bottom: 20px;
      }

      .info__button {
        color: #fff;
        background-color: var(--dark-red);
        font-family: AirbnbCereal-Bold;
        font-size: 12pt;
        height: 50px;
        width: 150px;
        border: none;
        border-radius: 5px;

        &:hover {
          background-color: var(--light-red);
          cursor: pointer;
        }
      }
    }

    .visual {
      .visual__image {
        height: auto;
        width: 300px;
        align-items: top;
        justify-content: top;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85% !important;
    margin-bottom: 25px !important;
    margin-top: 25px !important;

    .content {
      display: flex;
      flex-direction: column;

      .info {
        font-size: 16px;
        text-align: center;
        justify-content: center;
        align-items: center;

        .info__title {
          font-size: 26px;
          text-align: center;
        }
        .info__description {
          text-align: center;
        }
      }

      .visual {
        display: none;
      }
    }
  }
}
