.footer {
  height: 175px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(228, 166, 166, 0.2);
  gap: 20px;

  .footer__logo {
    height: 35px;
    width: 35px;
  }

  .footer__socials {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: var(--dark-red);
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .footer__title {
    font-family: AirbnbCereal-Light;
    font-size: 16px;
    color: var(--light-red);
  }
}
