* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "AirbnbCereal";
  src: local("AirbnbCereal"),
    url(./assets/fonts/cereal-airbnb/AirbnbCereal-Book.ttf) format("opentype");
}

@font-face {
  font-family: "AirbnbCereal-Medium";
  src: local("AirbnbCereal-Medium"),
    url(./assets/fonts/cereal-airbnb/AirbnbCereal-Medium.ttf) format("opentype");
}

@font-face {
  font-family: "AirbnbCereal-Bold";
  src: local("AirbnbCereal-Bold"),
    url(./assets/fonts/cereal-airbnb/AirbnbCereal-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "AirbnbCereal-Light";
  src: local("AirbnbCereal-Light"),
    url(./assets/fonts/cereal-airbnb/AirbnbCereal-Light.ttf) format("opentype");
}

:root {
  --black: #000000;
  --light-red: #9c3d3d;
  --dark-red: #52101d;
  --pink: #e4a6a6;
}
